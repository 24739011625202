<template>
    <div>
        <div class="bundles-body">
            <div v-show="loading" class="loading-spinner">
                <b-spinner class="m-auto" variant="primary" label="Spinning"></b-spinner>
            </div>
            <div class="page-header clear-filter" filter-color="bundles-cover-shadow"
                 :class="{'page-header-small': false}">
                <parallax
                        class="page-header-image"
                        :style="[{'z-index': '-4'},{'height': '300%'},{'width': '200%'}]"
                >
                    <div class="bundles-parallax"></div>
                </parallax>
                <div class="content-center">
                    <div class="container">
                        <h1 v-if="title" class="title">{{$t(title)}}</h1>
                        <h3 v-if="text" class="text-center">{{$t(text)}}</h3>
                    </div>
                </div>
            </div>
            <div v-show="isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="20" :size="9"
                           :group-count="4"
                           :min-left="2" :max-left="7"></HexaTiles>
            </div>
            <div v-show="!isMobile">
                <HexaTiles style="position: absolute; top: 12rem; left: 0;z-index: -5;" :count="5" :size="14"
                           :group-count="2"
                           :min-left="0" :max-left="1"></HexaTiles>
                <HexaTiles style="position: absolute; top: 12rem; right: 0;z-index: -5;" :count="5" :size="14"
                           :group-count="2"
                           :min-left="-3" :max-left="-1"></HexaTiles>
            </div>
            <parallax class="page-parallax-bg"></parallax>
            <b-row class="items-container mx-auto clear-filter" filter-color="bundles-page-shadow">
                <b-col class="items-container-2">
                    <b-row class="bundle-main" :class="{'w-100': isMobile}">
                        <b-col>
                            <b-row v-if="!notfound">
                                <b-col cols="10" sm="8" md="7" lg="6" xl="4" class="mx-auto mb-5">
                                    <div class="bundle-image-container" :style="[{'background-image': 'url(' + img + ')'},{'text-align': isMobile ? 'center' : 'left'}]" />
                                    <b-row v-if="true" class="px-3">
                                        <b-col cols="4" class="px-0">
                                            <router-link tag="button" class="btn btn-primary bundle-page-description text-center w-100" style="font-size: 1.2em;"
                                                         :to="{name: 'bundles'}">
                                                {{backBtnTitle}}
                                            </router-link>
                                        </b-col>
                                        <b-col cols="8" class="px-0">
                                            <router-link :disabled="disabledShop()"
                                                tag="button" class="btn bundle-page-description text-center w-100" style="font-size: 1.2em;"
                                                         :class="{'btn-secondary': disabledShop(), 'btn-golden': !disabledShop()}"
                                                         :to="{name: 'shop-connect'}">
                                                <i class="fa fa-shopping-cart mr-2"></i> Vásárlás
                                            </router-link>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col cols="12" sm="9" md="9" lg="6" xl="8">
                                    <div>
                                        <b-row>
                                            <span class="bundle-title">{{bundleTitle}}</span>
                                            <b-tabs class="ml-lg-4 ml-md-2" pills>
                                                <b-tab v-for="option in options" :key="option.optionId"
                                                       @click="selectOption(id, option.optionId)">
                                                    <template #title>
                                                        <div>{{option.days + ' NAP'}}</div>
                                                    </template>
                                                </b-tab>
                                            </b-tabs>
                                        </b-row>
                                        <div class="mx-0 px-0 float-right">
                                            <div v-if="limit" style="border-radius: 0.5rem; background-color: #192537;
                                                        color: goldenrod; box-shadow: 0 5px 25px 0 rgba(100, 100, 100, 0.2);"
                                                 class="bundle-description px-2 py-2 font-weight-bold text-center">
                                                {{ getLimitInfo() }}
                                            </div>
                                        </div>
                                    </div>
                                    <ul v-if="selectedOption" class="bundle-page-description">
                                        <li v-if="(selectedOption.price !== selectedOption.originalPrice || selectedOption.days !== selectedOption.originalDays)">
                                            <span class="text-danger font-weight-bold">AKCIÓS </span>
                                            <span v-if="selectedOption.days !== selectedOption.originalDays">
                                                <span class="text-strikethrough">({{selectedOption.originalDays}})</span>
                                                <span class="text-golden font-weight-bold"> {{selectedOption.days}} nap: </span>
                                            </span>
                                            <span v-else>
                                                {{selectedOption.days}} nap:
                                            </span>

                                            <span v-if="selectedOption.originalPrice !== selectedOption.price">
                                                <span class="text-strikethrough">({{self.formatNumberWithUnit(selectedOption.originalPrice, '', false, 0)}})</span>
                                                <span class="text-golden font-weight-bold"> {{self.formatNumberWithUnit(selectedOption.price, 'Ft', false, 0)}}</span>
                                            </span>
                                            <span v-else>
                                                {{self.formatNumberWithUnit(selectedOption.price, 'Ft', false, 0)}}
                                            </span>
                                        </li>
                                        <li v-else>{{selectedOption.days}} nap: {{self.formatNumberWithUnit(selectedOption.price, 'Ft', false, 0)}}</li>
                                        <li v-if="odds">Átlag odds: {{ odds }}</li>
                                        <li v-if="stake">Tétméret: {{ stake }}</li>
                                        <li v-if="intensity">Csoport intenzitása: {{ intensity }}</li>
                                        <li v-if="sports">Sportágak: {{ sports }}</li>
                                        <li v-if="infoLine">{{ infoLine }}</li>
                                        <li v-if="bettingOffice">A tippek megjátszásához szükséges fogadóiroda: {{ bettingOffice }}</li>
                                        <li v-if="otherInfo && otherInfo.length > 0">
                                          További információk:
                                          <ul>
                                            <li v-for="(info, idx) in otherInfo" :key="idx">{{ info }}</li>
                                          </ul>
                                        </li>
                                    </ul>
                                    <div class="mt-4" v-if="recommendation">
                                        <div class="bundle-subtitle">Kiknek ajánljuk?</div>
                                        <ul class="bundle-page-description">
                                            <li v-for="item in recommendation" :key="item">{{item}}</li>
                                        </ul>
                                        <div class="bundle-page-description font-italic"
                                             v-if="recommendationExtraLine">{{recommendationExtraLine}}
                                        </div>
                                    </div>
                                    <div class="mt-4" v-if="services">
                                        <div class="bundle-subtitle">Tartalma</div>
                                        <ul class="bundle-page-description">
                                            <li v-for="item in services" :key="item">{{item}}</li>
                                        </ul>
                                    </div>
                                    <div class="mt-3" v-if="offices">
                                        <div class="bundle-subtitle">Ajánlott fogadóirodák</div>
                                        <div class="bundle-page-description">{{offices}}</div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
    import HexaTiles from "../components/HexaTiles";
    import backend from "../../plugins/backend";
    import store from "../../plugins/store";

    export default {
        name: "Bundle",
        components: {
            HexaTiles
        },
        computed: {
            isMobile() {
                return this.$root.isMobile;
            },
            loading() {
                return store.getters.isLoading;
            },
            self() {
                return this;
            }
        },
        props: ['id'],
        data() {
            return {
                heroSrc: '/img/hero-collage.jpg',
                title: 'bundles.title',
                text: 'bundles.subtitle',
                bundleTitle: '',
                options: [],
                selectedOption: null,
                amount: null,
                limit: null,
                stake: null,
                odds: null,
                recommendation: [],
                recommendationExtraLine: '',
                description: '',
                services: [],
                sports: '',
                offices: '',
                img: '',
                notfound: false,
                backBtnTitle: '<< Vissza',
                bundleConfigs: []
            }
        },
        methods: {
            init() {
                let self = this;
                let id = self.id;
                if (id) {
                    backend.getBundleConfigs().then(response => {
                        this.bundleConfigs = response.body;
                    }).catch(err => {
                    });

                    backend.getBundleById(id).then(response => {
                        let bundle = response.body;
                        self.bundleTitle = bundle.title;
                        self.options = bundle.options;
                        self.selectedOption = self.options[0];
                        self.limit = bundle.limit;
                        self.amount = bundle.amount;
                        self.odds = bundle.odds;
                        self.stake = bundle.stake;
                        self.recommendation = bundle.recommendation;
                        self.recommendationExtraLine = bundle.recommendationExtraLine;
                        self.description = bundle.description;
                        self.services = bundle.services;
                        self.sports = bundle.sports;
                        self.offices = bundle.offices;
                        self.infoLine = bundle.infoLine;
                        self.bettingOffice = bundle.bettingOffice;
                        self.otherInfo = bundle.otherInfo;
                        self.intensity = bundle.intensity;
                        self.img = bundle.img;
                        if (!bundle.img.startsWith('http')) {
                            self.img = '/img/' + self.img;
                        }
                    }).catch(err => {
                        self.notfound = true;
                    });
                } else {
                    self.notfound = true;
                }
            },
            getLimitInfo() {
                let self = this;
                let config = this.bundleConfigs.find(x => {return x.type === self.id})
                return config.currentValue + '/' + config.limit + ' Fő'
            },
            disabledShop() {
                let self = this;
                let config = this.bundleConfigs.find(x => {return x.type === self.id})
                if (!config) return false
                else {
                    return config.currentValue >= config.limit
                }
            },
            selectOption(bundleId, optionId) {
                let self = this;
                self.selectedOption = self.options.find(x => {
                    return x.optionId === optionId
                });
            }
        },
        mounted() {
            this.init();
        },
        watch: {
            id() {
                this.init();
            }
        }
    }
</script>

<style scoped>
    .bundle-subtitle {
        line-height: 1.8em;
        font-size: 1.8em;
        font-weight: bold;
        color: whitesmoke;
    }

    .bundle-main {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    .bundle-link {
        cursor: pointer;
        color: goldenrod;
    }

    .bundle-link:hover {
        text-decoration: underline goldenrod;
    }

    .btn-primary.disabled:hover,
    .btn-primary.disabled:focus {
        opacity: 0.65;
        cursor: unset;
    }
</style>