<template>
  <div class="page-connect">
    <parallax class="page-parallax-bg"></parallax>
    <b-row class="main-container clear-filter" filter-color="connect-page-shadow">
      <b-col class="secondary-container">
        <b-row class="connect-container">
          <b-col class="connect-secondary-container">
            <b-row class="connect-title mb-4">
              <b-col>
                <div>Csatlakozás</div>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="connect-description">
                <div>Tisztelt leendő előfizetőnk!</div>
                <div>Amennyiben szeretnéd mevásárolni csomagunkat, vedd fel velünk a kapcsolatot!</div>
                <div>
                  <ul>
                    <li>
                      <a :href="connectFacebook" target="_blank">
                        <i class="fab fa-facebook"></i> Facebook
                      </a>
                    </li>
                    <li>
                      <a :href="connectInstagram" target="_blank">
                        <i class="fab fa-instagram"></i> Instagram
                      </a>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-lg-5 mt-0 mb-4" align-h="center">
              <b-col cols="12" md="10" xl="6" class="text-center">
                <a :href="connectMiamiSC" target="_blank"><img class="connect-img" :src="connectImg"/></a>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "NewCheckout",
  data() {
    return {
      connectImg: 'https://firebasestorage.googleapis.com/v0/b/sportfogadokclubja.appspot.com/o/public%2Fimg%2Fconnect.png?alt=media',
      connectFacebook: 'https://www.facebook.com/SportfogadokClubja',
      connectInstagram: 'https://www.instagram.com/sportfogadokclubja',
      connectMiamiSC: 'https://t.me/MiamiSC',
    }
  }
}
</script>

<style scoped>

</style>